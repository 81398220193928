import { For, createResource } from 'solid-js'
import TextStylePreview from '~/components/text-style-preview/text-style-preview'
import { getTextStyles } from '~/services/database/text-styles'

export default function TextStylesDebug() {
  const [textStyles] = createResource(async () => getTextStyles())
  return (
    <>
      <h1>{textStyles.latest?.length} Textstyles</h1>
      <div style={{
        display: 'grid',
        "grid-template-columns": "1fr 1fr",
        gap: "32px"
      }}>
        <For each={textStyles.latest}>
          {entry => <TextStylePreview textStyle={entry} />}
        </For>
      </div>
    </>
  )
}
